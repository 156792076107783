import {
  Vector3,
  Group,
} from "three";

import {
  CSS3DObject
} from "three/examples/jsm/renderers/CSS3DRenderer.js";


export const panelGroup = new Group()

let htmlPanelOpacity = 0;
let htmlPanelScalar = 250;
let htmlPanelScalarMantain = 250;

export function panelElement(id, alignment) {
  const div = document.createElement("div");
  div.className = `panel`
  div.style.width = `${container.clientWidth / 1}px`;
  div.style.height = `${container.clientHeight / 1}px`;
  div.style.pointerEvents = "none";
  div.style.backgroundColor = "transparent";

  const iframe = document.createElement("iframe");
  iframe.style.width = "100%";
  iframe.style.height = "100%";
  iframe.style.border = "0px";
  iframe.src = `./src/pages/${id}.html?alignment=${alignment}`;
  iframe.setAttribute = ("onLoad", 'load');


  div.style.opacity = 0.0;
  div.appendChild(iframe);

  const object = new CSS3DObject(div);
  if (window.innerWidth >= 1024) {
    object.scale.set(0.25, 0.25, .25)
  } else {
    object.scale.set(0.60, 0.60, .60)
  }
  return object;
}

export function hideAllPanels() {
  panelGroup.children.forEach((panel) => {
    panel.element.style.opacity = 0
  });
}

export function showAllPanels() {
  panelGroup.children.forEach((panel) => {
    panel.element.style.opacity = 1
  });
}

export function fadeInPanel(index, delta, camera, wormhole) {
  hideAllPanels();
  if (delta > 0) {
    if (index > 0) {
      htmlPanelOpacity = htmlPanelOpacity < 1 ? htmlPanelOpacity + 0.008 : 1;
    } else {
      htmlPanelOpacity = htmlPanelOpacity < 1 ? htmlPanelOpacity + 0.006 : 1;
    }
  } else {
    htmlPanelOpacity = htmlPanelOpacity > 0 ? htmlPanelOpacity - 0.008 : 0;
  }

  panelGroup.children[index].element.style.opacity = htmlPanelOpacity;
  var direction = new Vector3();
  camera.getWorldDirection(direction);

  if (htmlPanelScalar < htmlPanelScalarMantain) {
    htmlPanelScalar = htmlPanelScalarMantain;
  }
  panelGroup.children[index].position.copy(wormhole.geometry.parameters.path.getPointAt(getEndPoint(index))).add(direction.multiplyScalar(htmlPanelScalar));
  panelGroup.children[index].quaternion.copy(camera.quaternion);

}

export function getEndPoint(index) {
  switch (index) {
    case 1:
      return 0.10
    case 2:
      return 0.18
    case 3:
      return 0.24
    case 4:
      return 0.31
    case 5:
      return 0.48
    case 6:
      return 0.54
    case 7:
      return 0.63
    case 8:
      return 0.71
    case 9:
      return 0.89
    case 10:
      return 0.97
    default:
      return 0.04
  }
}

export function maintainPanel(index, camera) {
  hideAllPanels();
  panelGroup.children[index].visible = true;
  panelGroup.children[index].element.style.opacity = 1.0;
  htmlPanelOpacity = 1.0
  htmlPanelScalar = htmlPanelScalarMantain
  var direction = new Vector3();
  camera.getWorldDirection(direction);
  panelGroup.children[index].position.copy(camera.position).add(direction.multiplyScalar(htmlPanelScalar));
  panelGroup.children[index].quaternion.copy(camera.quaternion);
}

export function fadeOutPanel(index, delta, camera) {
  hideAllPanels();
  if (delta > 0) {
    htmlPanelOpacity = htmlPanelOpacity > 0 ? htmlPanelOpacity - 0.1 : 0;
  } else {
    htmlPanelOpacity = htmlPanelOpacity < 1 ? htmlPanelOpacity + 0.1 : 1;
  }
  panelGroup.children[index].element.style.opacity = htmlPanelOpacity;

}
