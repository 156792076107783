import { CatmullRomCurve3, Vector3, TubeGeometry, Mesh, MeshPhongMaterial, MeshBasicMaterial, VertexColors, BufferAttribute, ShaderMaterial, Group, Color, SplineCurve, Vector2, Vector4, DoubleSide } from "three"
import { wormholePath, wormholeSpline } from "./wormhole";

const midnightBlue = new Color('#030B33')
const baseRed = new Color('#E6263D')
const red = new Color('#CD162D') // -25 on red, - 16 on green, -16 on blue
const deepBlue = new Color('#CD0516')

const baseBlue = new Color('#0566B7')
const blue = new Color('#0026DB')

const orange = new Color('#FF5930')
const baseOrange = new Color('#FF7950')
const purple = new Color('#BA24FF')
const green = new Color('#11B979')

const sirius = new Color('#3CDCFF')
const antares = new Color('#FCED64')
const nebula = new Color('#FF55BB')
const peaGalaxy = new Color('#53F9BA')

const colors = new CatmullRomCurve3([
  new Vector3(blue.r, blue.g, blue.b),
  new Vector3(red.r, red.g, red.b),
  new Vector3(blue.r, blue.g, blue.b),
  new Vector3(orange.r, orange.g, orange.b),
  new Vector3(blue.r, blue.g, blue.b),
  new Vector3(red.r, red.g, red.b),
  new Vector3(blue.r, blue.g, blue.b),
  new Vector3(orange.r, orange.g, orange.b),
  new Vector3(blue.r, blue.g, blue.b),
  new Vector3(red.r, red.g, red.b),
  new Vector3(blue.r, blue.g, blue.b),
  new Vector3(orange.r, orange.g, orange.b),
  new Vector3(blue.r, blue.g, blue.b),
  new Vector3(red.r, red.g, red.b),
  new Vector3(blue.r, blue.g, blue.b),
  new Vector3(orange.r, orange.g, orange.b),
  new Vector3(blue.r, blue.g, blue.b),
  new Vector3(red.r, red.g, red.b),
  new Vector3(blue.r, blue.g, blue.b),
  new Vector3(orange.r, orange.g, orange.b),
  new Vector3(blue.r, blue.g, blue.b),
  new Vector3(red.r, red.g, red.b)
], false)

export function createTubeGroup(position = 'right', separation = 0.5, bend = 25, count = 40, radius = 29, tubeThickness = 0.175) {
  const centrePoints = wormholePath // 2d position
  const maxRadius = radius
  const normals = [...Array(centrePoints.length).keys()].map(o => {

    // get tangent vector at current point
    const tangent = new SplineCurve(centrePoints).getTangent(o / centrePoints.length);
    // find the unit normal vector to the tangent (using dot product of orthogonal vectors = 0 rule)
    const unitNormal = new Vector2(1, tangent.y === 0 ? 0 : - (tangent.x / tangent.y)).normalize();
    // add magnitude to unit normal vector ~ radius of wormhole
    const disposition = new Vector2(unitNormal.x, unitNormal.y).multiplyScalar(maxRadius);
    return position === 'right' ? disposition.multiplyScalar(-1) : disposition;
  });
  for (let i = 0; i < normals.length - 1; i++) {

    const a = new Vector2(normals[i].x, normals[i].y);
    const b = new Vector2(normals[i + 1].x, normals[i + 1].y);
    const angle = Math.acos((a.dot(b)) / (a.length() * b.length()));
    if (angle > 2 / 3 * Math.PI) {
      normals[i + 1] = normals[i + 1].multiplyScalar(- 1);
    }

  }
  const tubes = new Group()
  for (let i = 0; i < count; i++) {
    //const yPos = (1-Math.random()) * 5 - 8;
    //const yPos = (i * separation - count * separation / 3.1) + Math.random() * 30 - 5;
    const c = i / count * maxRadius
    const yPos = (c + Math.random() * separation) * Math.pow(-1, i)
    const s = Math.round(Math.random() * (40 - maxRadius) - 5)

    let displacements = normals
    if (maxRadius - Math.abs(yPos) < bend) {
      displacements = normals.map((o) => new Vector2(o.x, o.y).multiplyScalar(Math.log((maxRadius - Math.abs(yPos) + 1)) / Math.log(bend + 1)).addScalar(s))
    }

    const splinePath = wormholeSpline.points.map((o, idx, a) => {
      const declineThreshold = 2800
      const maintainMin = 1800
      if (idx < a.length - declineThreshold) {
        return new Vector3(o.x + displacements[idx].x, yPos, o.z + displacements[idx].y)
      } else {
        if (yPos < 0) {
          const declineValue = 20 / (1 + 10 * Math.pow(0.8, - (-28 + 48 * (idx - a.length + declineThreshold) / (declineThreshold - maintainMin)))) - 20
          const target = a.length - idx > maintainMin ? declineValue : -20
          return new Vector3(o.x + displacements[idx].x, yPos < target ? yPos : target, o.z + displacements[idx].y)
        } else {
          return new Vector3(o.x + displacements[idx].x, yPos, o.z + displacements[idx].y)
        }
      }
    })
    const spline = new CatmullRomCurve3(splinePath, false)
    const tube = new TubeGeometry(spline, 1000, tubeThickness + Math.random() / 50, 12)

    // tube.rotateY(Math.random() * Math.PI / 260);
    tube.setAttribute('color', new BufferAttribute(new Float32Array([...colors.getPoints(tube.attributes.position.count - 1).map(o => o.toArray()).flat()]), 3));

    const mesh = new Mesh(tube,
      new MeshPhongMaterial(
        {
          vertexColors: VertexColors,
          shininess: 0.9,
          reflectivity: 0.5,
          side: DoubleSide
        })
    );

    if (yPos >= 0) mesh.userData.topHalf = true

    mesh.material.transparent = true;
    tubes.add(mesh)
  }
  return tubes

  tubes.id = 'tubes' + position;
}

export function fadeInTube(t, percentStep, percentStepOffset, opacityDivider) {
  t.children.forEach(element => {
    let tubeOpacity = (Math.exp(percentStep - percentStepOffset)) / opacityDivider
    if (tubeOpacity < 1.0) {
      element.material.opacity = tubeOpacity;
    } else {
      element.material.opacity = 1;
    }
  });
}


export function fadeOutTube(t, percentStep, percentStepOffset, opacityDivider) {
  t.children.forEach(element => {
    let tubeOpacity = 1 - (Math.exp(percentStep - percentStepOffset)) / opacityDivider
    if (tubeOpacity > 0) {
      element.material.opacity = tubeOpacity;
    } else {
      element.material.opacity = 0;
    }
  });
}

export function maintainTube(t, tubeOpacity) {
  t.children.forEach(element => {
    element.material.opacity = tubeOpacity;
  });
}

export function hideTop(t, percentStep, percentStepOffset, opacityDivider) {
  t.children.forEach(element => {
    if (element.userData.topHalf) {
      if (percentStep) {
        let tubeOpacity = 1 - (Math.exp(percentStep - percentStepOffset)) / opacityDivider
        if (tubeOpacity > 0) {
          element.material.opacity = tubeOpacity;
        } else {
          element.material.opacity = 0;
        }
      } else element.material.opacity = 0;

    }
  });
}

export function showTop(t, percentStep, percentStepOffset, opacityDivider) {
  t.children.forEach(element => {
    if (element.userData.topHalf) {
      if (percentStep) {
        let tubeOpacity = (Math.exp(percentStep - percentStepOffset)) / opacityDivider
        if (tubeOpacity < 1.0) {
          element.material.opacity = tubeOpacity;
        } else {
          element.material.opacity = 1;
        }
      } else element.material.opacity = 1;
    }
  });
}
